import { GrfConfig } from "@/../grf.config";
import { HomePageLayout, Meta } from "@/components";
import { Search } from "@/components/search/algolia";
import { useAnalytics } from "@/hooks/useAnalytics";
import { NextPageWithLayout } from "@/pages/_app";
import { ReactElement, useEffect, useRef } from "react";

const HomePage: NextPageWithLayout = () => {
	const title = "Find the Perfect Group Run Near You";
	const { analytics, events, va } = useAnalytics();

	useEffect(() => {
		void analytics.page("Home");
	}, []);

	const search = useRef(
		<Search
			placeholder="Find a run by name, distance..."
			title="The Easiest Fastest Totally Free Way to Find the Perfect Running Group Near You"
			variant="primary"
			onSearchComplete={(term, e) => {
				const n = events.Searched;
				const p = { location: "Homepage", term };
				void analytics.track(n, {
					...p,
					...e
				});
				va.track(n, p);
			}}
		/>
	);

	return (
		<>
			<Meta
				canonical={GrfConfig.baseUrl}
				description="The easiest, fastest, totally free way to find the perfect running group near you."
				title={title}
			/>
			{search.current}
		</>
	);
};

HomePage.getLayout = function getLayout(page: ReactElement) {
	return <HomePageLayout>{page}</HomePageLayout>;
};

export default HomePage;
